.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* background-color: green; */
    height: 100%;
    justify-content: space-between;

}

.bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    /* background-color: blue; */
    width: 100%;
    /* height: 70%; */
    text-align: center;
}

.ContainerArea {
    display: flex;
    overflow: hidden;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 450px;
    /* background-color: red; */
}

.ContainerLogin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 70%;
    width: 100%;
    border: solid 1px;
    border-color: #020420;
    border-radius: 5px;
    padding: 30px;
    /* background-color: green; */
}

.ContainerInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #020402;
    border-radius: 5px;
    color: white;
    width: 100%;
    min-height: 50px;
    height: 50px;
    padding-left: 10px;
    justify-content: flex-end;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 8px;
    border: solid 1px;
}

.ContainerInput:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    color:black;
    width: 100%;
    min-height: 50px;
    height: 50px;
    padding-left: 10px;
    justify-content: flex-end;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 8px;
    border: solid 1px;
    transition: background-color 0.35s ease;

}

.InputLogin {
    /* background-color: #020402; */
    background-color: transparent;
    border-style: hidden;
    border-radius: 5px;
    padding: 0px 15px;
    outline: none;
    width: 85%;
    height: 100%;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.InputLogin::placeholder {
    text-transform: none;
}

.InputLogin:hover {
    color: #020402;
    font-weight: 300;
    font-size: 14px;
    /* background-color: white; */
    background-color: transparent;
    padding-left: 0px 10px;
    border-style: hidden;
    border-radius: 5px;
    padding-left: 15px;
    outline: none;
    transition: background-color 0.35s ease;
}

.inputIcon {
    margin-right: 10px;
}

.WelcomeText {
    margin-bottom: 10px;
}

.InformationText {
    margin-bottom: 20px;
    font-weight: 300;
}


.ButtonLogin:hover {
    display: flex;
    background-color: #076633;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    color: white;
    outline: none;
    transition: background-color 0.35s ease;
}

.ButtonLogin:active {
    display: flex;
    background-color: black;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    color: white;
    border: 0.1px;
}

.ButtonLogin {
    display: flex;
    background-color: #FFBF00;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    color: white;
    border: 0.1px;
}

.h3 {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    
}

.h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
}

.h5 {
    padding-bottom: 16px;
    padding: 8px 10px 16px;
    font-weight: 300;
}