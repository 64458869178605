/* Grid.module.css */

.table {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  /* padding: 15px; */
  /* box-shadow: 0px 0px 5px #ccc; */
  border-radius: 3px;
  border: 1px solid #ececec;
  /* margin: 20px auto; */
  word-break: break-word; /* Adicionado aqui */
}

.thread {
  display: table-header-group;
  width: 100%;
  font-weight: bold;
}

.tbody {
  /* flex-direction: column; */
  width: 100%;
}

.tr {
  display: flex;
  padding: 12px 0px;
  border-left: 4px solid #fff;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: blue; */
  transition: box-shadow 0.7s ease, border-left 0.2s ease;
  z-index: 1;
}

.trHeader {
  pointer-events: none;
}

.th {
  padding-top: 15px;
  text-align: start;
  padding-right: 20px;
  font-weight: bold;
  /* background-color: green; */
  width: 100%;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  
}

.td {
  text-align: start;
  /* padding-left: 5px; */
  height: 40px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  /* background-color: green; */
  word-wrap: break-word; /* Adicionado aqui */
}

.buttonEdit,
.buttonTrash {
  display: flex;
  min-height: 90%;
  width: 90%;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 10px;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  border-radius: 3px;
  transition: background-color ease 0.25s;
}

.buttonEdit {
  background-color: #f2f2f2;
  color: #151515;
}

.buttonEdit:hover {
  background-color: #003572;
  color: white;
}



.buttonTrash {
  background-color: #f2f2f2;
  color: #151515;
}

.buttonTrash:hover {
  background-color: #990000;
  color: white;
}

.width-A {
  width: 15%;
}

.width-B {
  width: 10%;
}

.width-C {
  width: 20%;
}

.width-D {
  width: 15%;
}

.width-E {
  width: 10%;
}

.width-F {
  width: 15%;
}

.width-Z {
  width: 8%;
  margin-right: 10px;
 
}