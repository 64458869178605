.formContainer {
    display: flex;
    flex-direction: column ;
    background-color: #fff;
    row-gap: 5px;
    padding: 2% 5%;
    box-shadow: 0px 0px 10px #323232;
    border-radius: 0px 0px 8px 8px;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    
}

.inputArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: green; */
    resize: both;
}

.input {
    width: 100%;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 5px;
    height: 100%;
    min-height: 40px;
    resize: both;
}

.inputDropdown {
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 5px;
    height: 100%;
    min-height: 40px;
    resize: both;
}

.fileInput {
    /* background-color: blue; */
    width: 100%;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 5px;
    height: 100%;
    min-height: 40px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .fileInput:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }
  
.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .dropzone:hover {
    background-color: #f0f0f0;
  }
  

.label {
    width: 100%;
    font-weight: 500;
    word-wrap: break-word;
    text-wrap: nowrap;
}

.alert {
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    background-color: #151515;
    font-weight: bold;
    text-align: center;
    width: 100%;
    min-height: 50px;
    margin-bottom: 5px;
  }
  
  .alert h4 {
    color: #FFBF00;
    margin: 0; 
    font-weight: normal;
  }

  .alertIcon {
    color:#FFBF00;  
    font-size: 30px;
    margin-right: 10px;
  }

.divider {
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 20px;
    margin-bottom: 15px;
  } 

.button {
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    font-weight: bold;
    background-color: #ececec;
    color: black;
    height: 42px;
    transition: background-color 0.2s ease;
    margin-top: 15px;
}

.button:hover {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background-color: #000;
    color: white;
    height: 42px;
    transition: background-color 0.2s ease;
}

.formPermissions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px; 
}

.inputAreaPermissions {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9; 
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.specialField {
    display: flex;
    flex-direction: column;
    background-color: #000; 
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: white
}

.labelPermissions {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
}

