.formContainer {
    display: flex;
    flex-direction: column ;
    /* align-items: flex-end; */
    /* flex-wrap: wrap; */
    background-color: #fff;
    row-gap: 5px;
    padding: 2% 10%;
    box-shadow: 0px 0px 10px #323232;
    border-radius: 0px 0px 8px 8px;
    overflow-y: scroll;
    /* min-height: 100%;
    min-width: 100%;
    max-height: 100%;
    max-width: 100%; */
    height: 100%;
    width: 100%;
    
}

.inputArea {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  resize: both;
}

.input {
    width: 100%;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 5px;
    height: 100%;
    min-height: 40px;
    resize: both;
}

.inputDropdown {
  width: 100%;
  /* padding: 0 10px; */
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 100%;
  min-height: 40px;
  /* word-wrap: break-word; */
  /* overflow-wrap: break-word; */
  resize: both;
  /* text-transform: capitalize; */
}

/* styles.module.css */

/* Estado normal */
.fileInput {
    /* background-color: blue; */
    width: 100%;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 5px;
    height: 100%;
    min-height: 40px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /* text-transform: capitalize; */
  }
  
  /* Hover */
  /* .fileInput:hover {
    border-color: #999;
  } */
  
  /* Quando clicado */
  .fileInput:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }
  
  /* FormBooks.module.css */

.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .dropzone:hover {
    background-color: #f0f0f0;
  }
  

  .button {
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    font-weight: bold;
    background-color: #ececec;
    color: black;
    height: 42px;
    transition: background-color 0.2s ease;
    margin-top: 15px;
}

.button:hover {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background-color: #000;
    color: white;
    height: 42px;
    transition: background-color 0.2s ease;
}

.label {
    width: 100%;
    font-weight: 500;
    word-wrap: break-word;
    text-wrap: nowrap;
    /* Se necessário, adicione estilos para labels aqui */
}

.alert {
  display: flex;
  /* flex-direction: column; */
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: #151515;
  font-weight: bold;
  text-align: center;
  width: 100%;
  min-height: 50px;
  margin-bottom: 5px;
}
  
  .alert h4 {
    color: #FFBF00;
    margin: 0; /* Remova o margin padrão do h4 */
    font-weight: normal;
  }

  .alertIcon {
    color:#FFBF00;  
    font-size: 30px;
    margin-right: 10px;
  }

.divider {
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 20px;
    margin-bottom: 15px;
  } 
