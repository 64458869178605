@import url(./global.module.css);

.body {
    height: 100%;
    width: 100%;    
    /* background-color: green; */
    text-align: center; /* Centraliza conteúdo inline */
}

.bodyListagem {
    margin: 0 auto; /* Centraliza horizontalmente */
    min-width: 800px;
    max-width: 800px;
    /* background-color: orange; */
}

.listBody {
    display: flex;
    background-color: red;
}

.titlePage {
    position: static;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1em;
    text-align: start;
}

.buttonFilter {
    position: static;
    font-weight: 400;
    max-height: 100%;
    margin-bottom: 10px;
    font-size: 0.8em;
    color: var(--background-color);
    text-align: start;
    background-color: #0f0f0f;
    border-radius: 30px;
    padding: 3px 10px;
    border: 0px transparent;
    cursor: pointer;
}

.headerList {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    /* background-color: green; */
    /* margin-bottom: 5px; */
    color: var(--background-color);
    /* height: 45%; */
    min-height:25px;
    max-height: 60px;
    text-wrap: wrap;
    width: 100%;
}

.adobeIcon {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    /* border-radius: 6px; */
    /* background-color: #E30613; */
    background-color: var(--primary-color);
    justify-content: center;
    align-items: center;
    padding: 7px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    border: none;
    color: var(--background-color);
}
.adobeIcon:hover {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    /* border-radius: 6px; */
    background-color: black;
    /* background-color: #020402; */
    justify-content: center;
    align-items: center;
    padding: 7px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    border: none;
    color: #fff;
}

.listContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    border-left: 0px solid var(--background-color);
    border-right: 18px solid transparent;
    background-color: var(--background-color);
    /* background-color: aqua; */
    transition: border-color ease-in-out 0.2s, padding-left 0.2s ease-in-out, border-left 0.2s ease-in-out,border-right 0.2s ease-in-out;
}

.listContainer:hover {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    border-right: 0px solid transparent;
    border-left: 8px solid #990000;
    background-color: dark;
    transition: border-color ease-in-out 0.2s, padding-left 0.2s ease-in-out, border-left 0.2s ease-in-out, border-right 0.2s ease-in-out;
}

.autorArea {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 500;
    font-size: 0.9em;
    width: auto;
    /* height: 26px; */
    background-color: var(--background-color);
    /* background-color: red; */
    color: var(--primary-color);
    padding: 0px 10px;
    padding-left: 0px;
    text-wrap: wrap;
    text-align: start;
}

.descriptionArea {
    display: flex;
    justify-content: flex-start;
    font-size: 0.875em;
    align-items: center;
    width: 100%;
    min-height: 50%;
    height: auto;
    text-align: start;

    /* background-color: #020402; */
    /* border-radius: 6px;  */
    color: var(--primary-color);
    /* color: black; */
    padding: 3px 0px;
    padding-left: 0px;
    font-size: 110%;
    text-wrap: wrap;
    /* overflow: visible; */
    line-height: 1.5;
    
}


.lineFilter {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    /* background-color: chartreuse; */
    gap: 5px;
    /* padding-bottom: 15px; */
    margin-bottom: 15px;
    max-height: 150px;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
    /* border-bottom: 1px solid #ececec; */
    border: 0.5px solid #acacac;
}

.lineTitleAndButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: solid 1px #ececec;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.inputSearchDiv {
    display: flex;
    width: 100%;
    /* background-color: purple; */
}

.inputSearch {
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    padding: 10px 15px;
    /* margin-right: 10px; */
    font-size: 0.9em;
    transition: background-color 0.2s ease, color 0.2s ease;
    /* outline-color: #c9c9c9;
    outline-width: 0.5px; */
    width: 100%;
    height: 40px;
  }

.inputSearch:focus-visible {
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 0.9em;
    transition: background-color 0.2s ease, color 0.2s ease;
    width: 100%;
    height: 40px;
    outline-color: #c9c9c9;
    outline-width: 0.5px;
  }

.containerFilter {
    display: grid;
    /* flex-direction: row; */
    justify-content: space-between;
    max-height: 100%;
    width: 100%;
    /* column-gap: 20px; */
    gap: 5px;
    align-items: center;
    /* background-color: aqua; */
    padding-bottom: 5px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    /* gap: 0px; */
}

.buttonClearFilter {
    font-size: 1.1em;
    color: black;
    background-color: transparent;
    text-decoration-line: underline;
    border: 0px;
    text-align: start;
    cursor: pointer;
}

@media (max-width: 980px) {

    .bodyListagem {
        display: flex;
        flex-direction: column;
        min-width: auto;
        max-width: auto;
        justify-content: center;
        /* background-color: orange; */
    }

    .lineFilter {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: chartreuse; */
        gap: 5px;
        min-height: 100px;
        max-height: 260px;
        margin-bottom: 15px;
        
        /* border-bottom: 1px solid #ececec; */
    }

    .containerFilter {
        display: grid;
        /* flex-direction: row; */
        justify-content: space-between;
        max-height: 100%;
        width: 100%;
        /* column-gap: 20px; */
        gap: 5px;
        align-items: center;
        /* background-color: aqua; */
        padding-bottom: 5px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        /* gap: 0px; */
    }

    .buttonClearFilter {
        text-align: start;
    }

    .body {
        padding: 0px 5px;
    }
    

}

