.modal_dialog {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8); /* Fundo preto semi-transparente */
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  z-index: 1050;
  align-items: center;
  justify-content: center;
  overflow-y: scroll; /* Mostra a barra de rolagem mesmo quando não há conteúdo suficiente para rolar */

}

/* FormModal.module.css */
.modal_content {
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  /* background-color: rgba(0, 255, 195, 0.5); */
  justify-content: space-between;
  align-items: center;
  height: 85%;
  width: 60%;
  
}

.modal_content.show,
.modal_dialog.show {
  opacity: 1; /* Muda para opacidade 1 quando a classe .show é aplicada */
}

.modal_header {
  display: flex;
  flex-direction: column;
  border-radius: 5px 5px 0px 0px;
  background-color: #000;
  justify-content: center;
  align-items: center;
  color: white;
  height: 50px;
  /* height: 20%; */
  min-width: 100%;
  /* margin-bottom: 10px; */
  z-index: 1100;
  /* box-shadow: 0px 0px 10px #323232; */

}


.titleModalHeader {
  display: flex;
  position: absolute;
  font-weight: 300;
  align-self: center;
  justify-self: flex-start;
}
.modal_body {
  display: flex;
  /* background-color: purple; */
  /* position: absolute; */
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  display: flex;
  height: 92%;
  width: 100%;
  /* top: 0px; */
  /* bottom: 0px;
  left: 0px;
  right: 0px; */
}

.close_button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: self-end;
  height: 80%;
  min-width: 50px;
  margin-right: 4px;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  background-color: #db3d3d;
  font-weight: 500;
  border: none;
  transition: background-color 0.2s ease;

}