.body {
  width: 100%;
}

.table {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 15px;
  /* box-shadow: 0px 0px 5px #ccc; */
  border-radius: 3px;
  border: 1px solid #ececec;
  /* margin: 20px auto; */
  word-break: break-word; /* Adicionado aqui */
}

.thread {
  display: table-header-group;
  width: 100%;
  font-weight: bold;
}

.tbody {
  width: 100%;
}

.trHead,
.tr {
  display: flex;
  padding: 12px 0px;
  border-left: 4px solid #fff;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: box-shadow 0.7s ease, border-left 0.2s ease;
  z-index: 1;
}

.th {
  text-align: start;
  /* padding-right: 20px; */
  font-weight: bold;
  width: 100%;
  overflow: auto;
  word-wrap: break-word; /* Adicionado aqui */
}

.td {
  text-align: start;
  /* padding-left: 5px; */
  height: 40px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  word-wrap: break-word; /* Adicionado aqui */
}

.headerActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  /* background-color: green; */
}

.headerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.buttonsWidth {
  width: 65%; /* ou qualquer valor que você desejar */
}

.buttonEdit,
.buttonTrash {
  display: flex;
  height: 90%;
  width: 90%;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 10px;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  border-radius: 3px;
  transition: background-color ease 0.25s;
}

.buttonEdit {
  background-color: #f2f2f2;
  color: #151515;
}

.buttonEdit:hover {
  background-color: #003572;
  color: white;
}



.buttonTrash {
  background-color: #f2f2f2;
  color: #151515;
}

.buttonTrash:hover {
  background-color: #990000;
  color: white;
}



.inputSearch {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 200;
  transition: background-color 0.2s ease, color 0.2s ease;
  width: 350px;
  height: 35px;
}

/* .inputSearch:hover,
.inputSearch:focus {
  background-color: black;
  border-color: black;
  color: white;
} */

.inputSearch:hover::placeholder,
.inputSearch:focus::placeholder {
  color: rgb(156, 156, 156);
  transition: color 0.2s ease;
}

.inputSearch::placeholder {
  color: #a1a1a1;
}

.inputSearch:focus {
  outline: none;
}

.iconCreate {
  /* display: flex; */
  cursor: pointer;
  width: 45px;
  height: 45px;
  transition: color ease-in-out 0.3s;
  
}

.iconCreate:hover {
  /* display: flex; */
  cursor: pointer;
  width: 45px;
  height: 45px;
  color: #008a55;
  transition: color ease-in-out 0.3s;
}

/* GridBooks.module.css */

.tdCenter {
  /* width: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background-color: blue; */
  vertical-align: middle;
}


.buttonActive {
  display: flex;
  height: 90%;
  width: 90%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  border-radius: 100px;
  transition: background-color ease 0.25s;
  background-color: #008a55; /* Verde para ativo */
  color: white;
}

.buttonInactive {
  display: flex;
  height: 90%;
  width: 90%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  border-radius: 100px;
  transition: background-color ease 0.25s;
  background-color: #990000; /* Vermelho para inativo */
  color: #fff;
}

