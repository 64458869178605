@font-face {
    font-family: "Posterama 1913 W01 Bold";
    src: url('../../public/fonts/Posterama1913W01Bold.ttf') format('truetype');
    /* Outras propriedades da fonte, se necessário */
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Sora", sans-serif;
    /* font-family: sans-serif; */
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    position: relative;
}

.body {
    font-size: 0.875rem;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    font-weight: 300;
    background-color: var(--background-color);
    padding: 0px 5% 15px;
    flex-direction: column;
    overflow-x: hidden;
    /* background-color: green;  */
};

h3 {
    color: #020402;
    font-weight: 500;
}

h4 {
    color: #020402;
    font-weight: 500;
    font-size: 14px;
}

:root {
    --primary-color: #990000;
    --secondary-color: #24292e;
    /* --background-color: rgb(237, 237, 201); */
    --background-color: rgb(255, 255, 255);
    --primary-text-color: #990000;
    --secondary-text-color: #24292e;

}

