/* AcervoFotografico.module.css */

.body {
  display: flex;
  align-self: center;
  flex-direction: column;
  height: 100%;
  /* background-color: green; */
  align-items: center;
}

.bodyContent {
  display: flex;
  flex-direction: column;
  max-width: 800px; 
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
}

.textWelcome {
  font-family: "Posterama 1913 W01 Bold";
  width: 100%;
  align-content: center;
  background-color: #990000;
  color: #f4d743;
  min-height: 50px;
  text-align: center;
  margin-bottom: 15px;
}

.lineSeparate {
  height: 1px;
  width: 300px;
  background-color: #e4e4e4;
  border-radius: 10px;
  margin: 30px 0px;
}

.paragraph {
  color: black;
  text-align: justify;
  line-height: 1.7;
  word-wrap: normal;
  width: 100%;
  margin-bottom: 15px;
}

.containerGrid {
  display: flex;
  justify-content: center;
}

.grid {
  display: flex;
  flex-direction: row; /* Adiciona direção em linha */
  flex-wrap: wrap; /* Permite quebrar para a próxima linha se necessário */
  gap: 20px; /* Espaçamento entre as imagens */
  justify-content: center; /* Alinhamento inicial à esquerda */
}


.image {
  width: 50%; /* Largura de 50% */
  max-width: 100%; /* Limite máximo de largura */
  height: auto; /* Altura proporcional */
  border-radius: 3px;
  margin-bottom: 10px; /* Adiciona espaçamento entre as imagens */
}


.imagemCategoria {
  width: 100%;
  height: auto;
  /* transform: scale(0.5); */

  /* border: 10px solid yellow; */
}
