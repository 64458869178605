 .bodyPage {
   display: flex;
   justify-content: center;
   align-content: center;
   align-items: center;
   flex-direction: column;
   align-items: center;
   /* background-color: blue; */
   /* width: 80%; */
 }

 /*Filho de bodyPage*/
 .container {
   display: flex;
   /* justify-content: space-around; */
   gap: 15px;
   /* background-color: green; */
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   max-width: 80%;
 }

 .textWelcome {
  cursor: pointer;
   font-family: "Posterama 1913 W01 Bold";
   width: 100%;
   align-content: center;
   background-color: #03705a;
   color: #f4d743;
   min-height: 40px;
   text-align: center;
   text-decoration: underline;
   margin-bottom: 15px;
 }

 /*Filho de container*/
 .photo-card {
   display: flex;
   /* flex: 0 0 calc(33.33% - 20px);  */
   margin: 10px;
   justify-content: center;
   align-items: center;
 }

 .modal {
   z-index: 9999;
 }

 .modal-backdrop {
   z-index: 9998;
   background-color: rgba(0, 0, 0, 0.5);
 }

 /* Estilização para o modal */
 .modal-content {
   background-color: transparent;
   border: none;
 }

 .modal-dialog {
   max-width: 80%;
 }

 .modal-body {
   padding: 0;
 }

 .carousel-item {
   text-align: center;
 }

 .carousel-item img {
   max-width: 100%;
   max-height: 70vh;
   margin: auto;
 }