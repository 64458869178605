.Background {
    display: flex;
    background-color: #020402;
    min-height: 60px;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: sticky;
    padding: 10px 14px;
    top: 0;
    z-index: 0;
    border: solid 2px #020402;
    border-radius: 5px;
    /* box-shadow: 0px 15px 30px 10px white; */
}

.BackgroundNavbar {
    /* background-color: red; */
    /* padding: 10px; */
    height: 100%;
    width: 100%;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-radius: 5px;
    /* box-shadow: 0px 4px 2px 0px #020402; */
}

.BackgroundComponents{
    display: flex;
    justify-content: space-between;
    width: 400px;
    height: 100%;
    /* background-color: blue; */
    border-radius: 13px;
}

.BackgroundComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    background-color: #191919;
    font-weight: 500;
    border: none;
    transition: background-color 0.2s ease;
}

.BackgroundComponent:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
    border-radius: 3px;
    cursor: pointer;
    color:white;
    background-color: #990000;
    font-weight: 500;
    border: none;
    transition: background-color 0.2s ease;
}

.ContainerButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
