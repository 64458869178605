.body {
    display: flex;
    flex-direction: column;
    /* background-color: green; */
    height: 100%;
    /* justify-content: space-between; */
    align-items: center;
}
.containerArea {
    display: flex;
    height: 400px;
    width: 400px;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
    flex-direction: column;
    text-align: center;
}

.headerGridCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    /* background-color: aqua; */
    width: 100%;
    min-height: 40px;
    margin-bottom: 20px;
}

.buttonRegister {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 335px;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    background-color: #008a55;
    font-weight: 500;
    border: none;
    transition: background-color 0.2s ease;
}

.buttonRegister:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 335px;
    border-radius: 3px;
    cursor: pointer;
    color: #fff;
    background-color: #000000;
    font-weight: 500;
    border: none;
    transition: background-color 0.2s ease;

}

.iconCreate {
    /* display: flex; */
    cursor: pointer;
    width: 45px;
    height: 45px;
    transition: color ease-in-out 0.3s;
    
  }
  
  .iconCreate:hover {
    /* display: flex; */
    cursor: pointer;
    width: 45px;
    height: 45px;
    color: #008a55;
    transition: color ease-in-out 0.3s;
  }
  