.bodyPhotoCard {
    display: flex;
    width: 100%;
    height: 200px;
    align-self: center;
    justify-content: center;
}

@media (max-width: 980px) {
    .bodyPhotoCard {
        display: flex;
        width: 100%;
        height: 150px;
        align-self: center;
        justify-content: center;
    }
}