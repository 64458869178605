.body {
    display: block;
    justify-content: space-between;
    /* background-color: green; */
    height: 100%;
}

.ContainerBody {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    /* padding: 20px; */
    text-align: center;
    /* background-color: blue; */
}

.image {
    width: 100px;
    height: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;
}


.LabelBiography {
    margin-bottom: 10px;
}

.paragraph {
    text-indent: 4em;
    text-align: justify;
    line-height: 1.7;
    word-wrap: normal;
    width: 100%;
    margin-bottom: 15px;
}