@import url(../styles/global.module.css);

.Background {
    display: flex;
    background-color: var(--background-color);
    /* background-color: red; */
    min-height: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: sticky;
    border-bottom: solid 1px #ececec;
    top: 0;
    z-index: 1000;
    /* box-shadow: 0px 5px 15px 10px white; */
}

.BackgroundNavbar {
    /* background-color: var(--background-color); */
    display: flex;
    /* background-color: green; */
    gap: 2%;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    /* box-shadow: 0px 4px 2px 0px #020402; */
}

.iconLogin {
    display: flex;
    cursor: pointer;
    max-width: 30px;
    min-width: 30px;
    /* background-color: #990000; */
    border-radius: 2px;
    padding: 2px;
    transition: background-color ease 0.3s, filter ease 0.3s
}

.iconLogin:hover {
    display: flex;
    filter: invert(100%);
    max-width: 30px;
    min-width: 30px;
    background-color: #fff;
    border-radius: 2px;
    padding: 2px;
}

.containerLogged {
    display: flex;
    flex-direction: row;
    gap: 5px;
    /* background-color: purple; */
}

.containerLogged {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    min-width: 30px;
    /* background-color: #131313; */
    background-color: black;
    color: white;
    /* padding: 3px; */
    border-radius: 3px;
    transition: background-color ease-out 0.2s, min-width ease 0.5s;
}

.containerLogged:hover {
    display: flex;
    cursor: pointer;
    align-self: center;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    min-width: 80px;
    /* background-color: #990000; */
    color: #880000;
    /* padding: 3px; */
    border-radius: 3px;
    transition: background-color ease-out 0.1s, min-width ease 0.5s;
}

.fontLogged {
    position: absolute;
    padding-left: 5px;
    padding-top: 7px;
    font-size: 12px;
    color: transparent;
    text-align: end;
    text-justify: center;
    background-color: transparent;
    height: 100%;
    width: 100%;
    z-index: 10000;
}

.fontLogged:hover {
    position: absolute;
    padding-left: 15px;
    padding-top: 7px;
    text-align: start;
    text-justify: center;
    font-size: 12px;
    color: white;
    transition: color ease 0.5s, font-size ease-in 0.2s;
}

.iconLogged {
    /* display: flex; */
    cursor: pointer;
    height: 100%;
    min-width: 30px;
    background-color: #131313;
    color: white;
    padding: 3px;
    border-radius: 3px;
    transition: background-color ease 0.2s, min-width ease 0.5s;
    z-index: 1001;

}

.iconLogged:hover {
    /* display: flex; */
    cursor: pointer;
    align-self: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 30px;
    /* background-color: blue; */
    color: #880000;
    padding: 3px;
    border-radius: 3px;
    transition: background-color ease 0.1s, min-width ease 0.5s;
    z-index: 1002;
}

.buttonAdm {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 60%;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    background-color: #171717;
    font-weight: 500;
    border: none;
    transition: background-color ease-out 0.1s;
}

.buttonAdm:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 60%;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    background-color: #171717;
    font-weight: 500;
    border: none;
    transition: background-color 0.2s ease;
}


.LinksContainer {
    display: flex;
    justify-content: center;
    color: var(--secondary-text-color);
    width: auto;
    height: 100%;
    /* background-color: yellow; */
    align-items: flex-end;

    padding: 0px;
    gap: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.LinksText {
    font-size: 15px;
    font-family: "Posterama 1913 W01 Bold";
    /* margin-right: 20px; */
    font-weight: bold;
    color: var(--secondary-text-color);
    text-overflow: ellipsis;
    text-align: right;
    overflow: hidden;
    width: min-content;
    text-wrap: nowrap;
    text-decoration: none;
    /* background-color: green; */
    border-bottom: 4px solid var(--primary-color);
}

.LinksText:hover {
    font-size: 15px;
    font-family: "Posterama 1913 W01 Bold";
    font-weight: bold;
    color: var(--primary-color);
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    text-decoration: none;
    /* background-color: red; */
    border-bottom: 4px solid var(--primary-color);
}

.FontOffFocus {
    color: white;
    word-spacing: 2px;
}

.FontLogo {
    font-family: "Posterama 1913 W01 Bold";
    font-size: 1.3em;
    min-width: 18%;
    height: 100%;
    align-self: baseline;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    text-decoration: none;
    /* background-color: purple; */
    color: var(--secondary-text-color);

}


.ContainerTextLogo {
    display: inline;
    justify-content: flex-start;
    color: var(--secondary-text-color);
    align-content: flex-end;
    height: 100%;
    min-width: 24%;
    /* background-color: blue; */
    padding: 0px;
}

.ContainerTextAdmin {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    color: var(--secondary-text-color);
    height: 100%;
    min-width: 24%;
    /* background-color: aquamarine; */
    padding: 0px;
    gap: 5px;
}

.MobileMenuIcon {
    display: none;
}

/* Estilos responsivos ************************************************************/
@media (max-width: 980px) {

    .MobileMenuIcon {
        visibility: visible;
        background-color: white;
        z-index: 1010;

    }

    .ContainerTextLogo {
        display: inline;
        color: var(--secondary-text-color);
        align-content: flex-end;
        text-align: center;
        height: 100%;
        min-width: 24%;
        /* background-color: blue; */
        padding: 0px;
    }

    .ContainerTextAdmin {
        visibility: hidden;
        max-width: 60px;
        min-width: 30px;
        display: flex;
        cursor: pointer;
    }

    .BackgroundNavbar {
        flex-direction: row-reverse;
         gap: 1%;

    }

    .MobileMenuIcon {
        display: flex;
        cursor: pointer;
        max-width: 60px;
        min-width: 30px;
        /* background-color: yellow; */
        border-radius: 2px;
        padding: 2px;
    }
    


    .LinksContainer {
        display: flex;
        /* Usar flex, mas esconder com opacidade */
        flex-direction: column;
        position: absolute;
        top: 59px;
        /* Ajuste conforme a altura da navbar */
        gap: 10px;
        left: 0;
        right: 0;
        height: 140px;
        min-width: 250px;
        background-color: fdfdfd;
        /* border-radius: 5px; */
        opacity: 0;
        padding: 10px;

        /* Começa invisível */
        transition: opacity 0.2s ease, transform 0.3s ease;
        /* Transições suaves */
        transform: translateY(-10px);
        /* Para dar um efeito de deslizamento */
        pointer-events: none;
        /* Desabilita eventos enquanto invisível */
    }

    .Show {
        opacity: 1;
        /* Fica visível */
        transform: translateY(0);
        /* Desliza para a posição original */
        pointer-events: auto;
        /* Habilita eventos quando visível */
        align-items: flex-end;
        background-color: #fdfdfd;
        /* background-color: blue; */
        padding: 10px;
        border-top: 0.5px solid #ececec;
        border-bottom: 0.5px solid #d1d1d1;
        transition: opacity 0.2s ease, transform 0.5s ease;
        /* box-shadow: 0px 40px 20px rgb(255, 255, 255);  */

    }

    .LinksText {
        padding: 0px;
        /* Espaçamento interno */
        text-decoration: none;
        /* Remover sublinhado */
        color: black;
        /* Cor do texto */
    }

    .LinksText:hover {
        background-color: rgba(0, 0, 0, 0.1);
        /* Efeito de hover */
    }
}


/*
.MobileNavOpen {
  display: block;
}

.MobileNavClosed {
  display: none;
}

.MobileNavContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px; // Largura da barra lateral 
  height: 100%;
  background-color: #333; // Cor de fundo da barra lateral 
  padding-top: 60px; // Espaço para evitar a sobreposição com a barra superior 
}

.MobileNavLinks {
  display: block;
  padding: 10px;
  color: #fff; // Cor do texto 
  text-decoration: none;
}

.MobileNavToggle {
  position: fixed;
  top: 20px;
  right: 20px;
  color: #fff; // Cor do texto do botão 
  cursor: pointer;
}
*/