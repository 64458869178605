@import url(../styles/global.module.css);

.FooterBackground {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: sticky; */
    /* overflow: hidden; */
    font-family: "Posterama 1913 W01 Bold";
    justify-content: center;
    font-weight: lighter;
    width: 100%;
    min-height: 80px;
    max-height: 80px;
    margin-top: auto;
    /* border-radius: 13px; */
    /* bottom: 0; */
    /* z-index: 10000; */
    border-top: solid 1px #ececec;
    /* background-color: red; */
    /* background-color: var(--background-color); */
    box-sizing: border-box;
}

.FooterImage {
    min-width: 150px;
    width: 300px;
    max-width: 350px;
    /* Largura de 50% */
    max-width: 100%;
    /* Limite máximo de largura */
    height: auto;
    /* Altura proporcional */
    border-radius: 3px;
    margin-bottom: 10px;
    /* Adiciona espaçamento entre as imagens */

}

.FooterDescription {
    font-family: "Posterama 1913 W01 Bold";
    color: var(--secondary-text-color);
    width: 100%;
    font-weight: 300;
    text-align: center;
    /* background-color: blue; */
    font-size: 12px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    align-self: center;
    padding: 13px 0px;
}

.FooterContactsBackground {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: var(--background-color);
    text-align: center;
    /* background-color: green; */
    font-size: 90%;
    overflow: hidden;
    flex-wrap: nowrap;
    justify-content: center;
}

.FooterContactPhone {
    flex-direction: row;
    display: flex;
    color: var(--secondary-text-color);
    justify-content: flex-end;
    padding-right: 30px;
    /* background-color: purple; */
    min-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FooterContactEmail {
    flex-direction: row;
    display: flex;
    color: var(--secondary-text-color);
    /* background-color: purple; */
    /* justify-content: flex-start; */
    justify-content: center;
    padding-left: 30px;
    min-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FooterContactsText {
    font-weight: 300;
    font-size: 12px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--secondary-text-color);
    font-family: "Posterama 1913 W01 Bold";

}

.FooterContactIcon {
    font-size: 20px;
    color: var(--secondary-text-color);
    /* transform: scaleX(-1); */
    margin-right: 10px;
}

.ContainerCircleIcons {
    display: flex;
    width: 130px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
}

.CircleSocialIcon {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: var(--secondary-text-color);
}

.SocialIcon {
    font-size: 17px;
    color: var(--background-color);
}